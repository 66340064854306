import React from 'react';
import { Link as EnturLink, Paragraph } from '@entur/typography';
import { TabPanel } from '@entur/tab';
import { getSiriExporterUrl } from '../../config';

const SiriEtLastCustomDownloadTab = () => {
    return (
        <TabPanel>
            <Paragraph style={{ padding: '0.5rem', paddingLeft: '0', marginTop: '0.5rem' }}>
                Use our data exporter to download query results as compressed CSV-files:{' '}
                <EnturLink as="a" href={getSiriExporterUrl()} target="_blank">
                    Real-Time data exporter
                </EnturLink>
            </Paragraph>
        </TabPanel>
    );
};

export default SiriEtLastCustomDownloadTab;
