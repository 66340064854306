type Environment = 'DEV' | 'STAGING' | 'PRODUCTION';

export function getEnvironment(): Environment {
    const location = window.location.origin;
    if (location.includes('localhost')) return 'DEV';
    if (location.includes('.staging.')) return 'STAGING';
    return 'PRODUCTION';
}

export function getDQReportUrl(): string {
    return getEnvironment() === 'PRODUCTION'
        ? 'https://data-quality.entur.no/'
        : 'https://data-quality.staging.entur.no/';
}

export function getSiriExporterUrl(): string {
    return getEnvironment() === 'PRODUCTION'
        ? 'https://siri-data-exporter.entur.no/'
        : 'https://siri-data-exporter.staging.entur.no/';
}

export function getDocsUrl(): string {
    return getEnvironment() === 'PRODUCTION'
        ? 'https://docs.data.entur.no/docs/public-data-catalog/'
        : 'https://docs.data.staging.entur.no/docs/public-data-catalog/';
}

export function getApiUrl(): string {
    switch (getEnvironment()) {
        case 'DEV':
            return 'http://localhost:3001';
        case 'STAGING':
            return 'https://api.staging.entur.io/public-data-catalog-api';
        default:
            return 'https://api.entur.io/public-data-catalog-api';
    }
}

export function getClientId(): string {
    switch (getEnvironment()) {
        case 'DEV':
            return 'RAxpS40hgrnopzaFemX6TT7PisnN6Cg6';
        case 'STAGING':
            return 'rKe8gJvTe5NvXNAhYKFyWnzO41EIE7p1';
        default:
            return '4gqdl864gDajxFA2LeaMxuReOQdQDXAm';
    }
}
